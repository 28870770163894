export default {
  path: "/setting",
  name: "Setting",
  component: () => import("@/views/Setting/Index"),
  redirect: "/setting/appSetting",
  meta: {
    isAuth: true,
  },
  children: [
    {
      path: "",
      component: () => import("@/views/Setting/Index"),
      redirect: "/setting/appSetting",
      meta: {
        menuTitle: "基础设置",
        menuIcon: "icon-setting",
      },
      children: [
        {
          path: "/setting/appSetting",
          name: "AppSetting",
          component: () => import("@/views/Setting/AppSetting"),
          meta: {
            menuTitle: "企业配置",
            isAuth: true,
          },
        },
        {
          path: "/setting/menuManage",
          name: "MenuManage",
          component: () => import("@/views/Setting/MenuManage"),
          meta: {
            menuTitle: "菜单管理",
            isAuth: true,
          },
        },
        {
          path: "/setting/authSetting",
          name: "AuthSetting",
          component: () => import("@/views/Setting/AuthSetting"),
          meta: {
            menuTitle: "权限配置",
            isAuth: true,
          },
        },
        {
          path: "/setting/pathSetting",
          name: "PathSetting",
          component: () => import("@/views/Setting/PathSetting"),
          meta: {
            menuTitle: "路径配置",
            isAuth: true,
          },
        },
        {
          // 新增管理员
          path: "/setting/addAdmin",
          component: () => import("@/views/Setting/EditAdmin"),
          name: "EditAdmin",
          meta: {
            isAuth: true,
          },
        },
        {
          // 短信模板
          path: "/setting/messageTemplate",
          component: () => import("@/views/Setting/MessageTemplate"),
          name: "MessageTemplate",
          meta: {
            isAuth: true,
          },
        },
        {
          // 短信签名
          path: "/setting/messageSign",
          component: () => import("@/views/Setting/MessageSign"),
          name: "MessageSign",
          meta: {
            isAuth: true,
          },
        },
      ],
    },
  ],
};
