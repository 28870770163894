import axios from "axios";
import config from "@/config/index";
import qs from "qs";
import logger from "./logger";
import { Message } from "element-ui";
import router from "@/router";
import store from "@/store";

/** 接口成功处理 */
function httpSuccessHandle(url, query, req, res) {
  logger.info(`接口[${url}]响应`, { query, req, res });
  return res;
}

/** 接口错误处理 */
function httpErrorHandle(err) {
  logger.error(`接口报错`, err);
  const { status, data } = err.response;
  switch (status) {
    case 401:
    case 412:
      router.push("/login");
      store.commit("updateLoginData", "");
      Message.error(data.error.message);
      return Promise.reject(err.response.data);
    case 403:
      Message.error(data.error.message);
      return Promise.reject(err.response.data);
    default:
      Message.error({
        duration: 2000,
        message: data.error ? data.error.message || "系统错误" : "系统错误",
      });
      return Promise.reject(err.response.data);
  }
}

/** 接口请求 */
function http(url, method = "GET", query = {}, data = {}, headers = {}) {
  // axios请求
  return axios({
    url: url.indexOf("://") > -1 ? url : `${config.apiPrefix}${url}`,
    method,
    data,
    params: Object.assign({}, query),
    headers: Object.assign(
      {
        "X-Access-Token": store.state.loginData.token || "",
      },
      headers
    ),
    timeout: 30000,
  }).then((res) => {
    return res.data || {};
  });
}

/** http json 请求 */
export function httpJSON(url, method = "GET", data = {}, query = {}, headers = {}) {
  return http(
    url,
    method,
    query,
    data,
    Object.assign(Object.assign({}, headers), {
      "Content-Type": "application/json",
    })
  )
    .then((res) => {
      return httpSuccessHandle(url, query, data, res);
    })
    .catch((err) => httpErrorHandle(err));
}

/** http urlencoded 请求 */
export function httpUrlEncoded(url, method = "GET", data = {}, query = {}, headers = {}) {
  return http(
    url,
    method,
    query,
    qs.stringify(data),
    Object.assign(Object.assign({}, headers), {
      "Content-Type": "application/x-www-form-urlencoded",
    })
  )
    .then((res) => {
      return httpSuccessHandle(url, query, data, res);
    })
    .catch((err) => httpErrorHandle(err));
}
