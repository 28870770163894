export default {
  path: "/marketing",
  name: "Marketing",
  component: () => import("@/views/Marketing/Index.vue"),
  redirect: "/marketing/actList",
  meta: {
    menuTitle: "自动营销",
    menuIcon: "icon-marketing",
    isAuth: true,
  },
  children: [
    {
      path: "/marketing/actList",
      name: "MarketingActList",
      component: () => import("@/views/Marketing/ActList.vue"),
      meta: {
        isAuth: true,
      },
    },
    {
      path: "/marketing/templateList",
      name: "MarketingTemplateList",
      component: () => import("@/views/Marketing/TemplateList.vue"),
      meta: {
        isAuth: true,
      },
    },
    {
      path: "/marketing/data",
      name: "MarketingData",
      component: () => import("@/views/Marketing/Data.vue"),
      meta: {
        isAuth: true,
      },
    },
    {
      path: "/marketing/pannel",
      name: "MarketingPannel",
      component: () => import("@/views/Marketing/Pannel.vue"),
      meta: {
        isAuth: true,
      },
    },
  ],
};
