<template>
  <div class="main">
    <TopMenu v-if="isShowTopMenu" />
    <LeftMenu v-if="isShowLeftMenu" />
    <div class="main-content" :class="{ 'margin-left': isShowLeftMenu }">
      <router-view class="m-page"></router-view>
    </div>
  </div>
</template>

<script>
import TopMenu from "@/components/common/TopMenu.vue";
import LeftMenu from "@/components/common/LeftMenu.vue";

export default {
  name: "App",
  components: { TopMenu, LeftMenu },
  data() {
    return {};
  },
  computed: {
    // 是否显示头部栏
    isShowTopMenu() {
      return !["/login"].includes(this.$route.path);
    },
    // 是否显示左侧菜单栏
    isShowLeftMenu() {
      if (this.$route.path.indexOf("/marketing") === 0) return false; // 自动营销栏不显示
      return !["/login"].includes(this.$route.path);
    },
  },
};
</script>

<style lang="less">
@import "~@/assets/less/global.less";

.main {
  position: relative;
  .main-content {
    transition: 0.5s all;
    margin-left: 0;
  }
  .margin-left {
    margin-left: 262px;
  }
}
.m-page {
  padding: 30px;
}
</style>
