import { httpJSON } from "@/libs/request";

// 发送验证码
export const SendCode = (data) => httpJSON("/common/SendCode", "POST", data);
// 手机登录
export const MobileLogin = (data) => httpJSON("/cms/MobileLogin", "POST", data);

// 获取基本品牌配置
export const getBaseConfigBrand = () => httpJSON("/cms/BaseConfigBrand", "GET");

// 后台管理-图片上传
export const Upload = (data) => httpJSON("/common/UploadFile", "POST", data);
