<template>
  <div class="left-menu" :class="{ min: isCollapse }">
    <el-menu
      :default-active="defaultActive"
      class="el-menu-vertical-demo"
      style="padding-bottom: 60px"
      :collapse="isCollapse"
    >
      <div class="submenu" v-for="item in currFirstRoutePathGroup" :key="item.path">
        <p class="title">
          <i v-if="item.meta && item.meta.menuIcon" :class="item.meta.menuIcon"></i>
          <span>{{ item.meta ? item.meta.menuTitle : "" }}</span>
        </p>
        <div class="childrenT">
          <p
            :class="{ active: child.path.indexOf(currSecondRoutePath) === 0 }"
            v-for="(child, i) in item.children"
            :key="i"
            @click="geRouter(child)"
          >
            {{ child.meta ? child.meta.menuTitle : "" }}
          </p>
        </div>
      </div>
    </el-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      defaultActive: "",
      activeName: "1",
    };
  },
  props: ["isCollapse"],
  computed: {
    // 当前一级路由路径
    currFirstRoutePath() {
      return `/${this.$route.path.split("/")[1]}`;
    },
    // 获取当前二级路由分组
    currFirstRoutePathGroup() {
      const route = this.$router.options.routes.find((item) => item.path === this.currFirstRoutePath);
      if (!route || !route.children) return;
      const groups = JSON.parse(JSON.stringify(route.children)); // 这里深拷贝是为了下面值不受影响
      // 二级路由校验
      groups.forEach((group) => {
        group.children = group.children.filter((item) => {
          if (!item.meta || !item.meta.menuTitle) return false;
          // 校验路由的情况
          if (item.meta.isAuth === true) return this.$store.getters.isAuth(item.name);
          return true;
        });
      });
      return groups;
    },
    // 获取当前二级路由路径
    currSecondRoutePath() {
      return this.$route.path.split("/").slice(0, 3).join("/");
    },
  },
  methods: {
    changTab(tab) {
      this.activeName = tab.paneName;
    },
    geRouter(item) {
      this.$router.push(item.path);
    },
  },
};
</script>

<style lang="less" scoped>
.formClass {
  padding: 20px 30px 0 30px;
}

.left-menu {
  position: fixed;
  left: 0;
  top: 58px;
  border-right: solid 1px #e6e6e6;
  z-index: 20;
  height: 100%;
  overflow: auto;
  transition: 0.5s all;
  width: 262px;
  padding-top: 16px;
  background: #ffffff;

  &.min {
    width: 64px;
  }

  .el-menu {
    border: 0;

    /deep/ .el-menu-item {
      height: 42px;
      line-height: 42px;
      margin-bottom: 4px;

      &.is-active {
        background: #eaf3fd;
        border-right: 4px solid #419eff;
      }
    }

    /deep/ .el-menu-item-group__title {
      padding: 0;
    }

    /deep/ .el-submenu .el-menu-item {
      min-width: 100px;
    }

    .el-submenu__title {
      height: 50px;
      line-height: 50px;
    }
  }

  .diyGroup {
    padding: 0 20px;

    .diy {
      .diy_top {
        margin-top: 17px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .diy_top_box {
          margin-left: 5px;

          > span {
            margin-left: 4px;
          }
        }

        .diy_top_txt {
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
        }
      }

      > ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 5px 0;
        margin-left: 25px;
        > li {
          width: 50%;
          font-size: 16px;
          line-height: 40px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          // margin-left: 25px;
          // margin-bottom: 20px;

          &:nth-child(2n) {
            margin-left: 0px;
            // margin-right: 15px;
          }
        }
      }
    }

    /deep/.el-button {
      width: 100%;
      height: 40px;
      background-color: #3373fe;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
    }

    /deep/ .el-tabs__nav {
      margin-left: 18px;
    }

    /deep/ .el-tabs__item {
      font-size: 18px;
    }

    /deep/.el-tabs__nav-wrap::after {
      content: normal;
    }
  }
}

.submenu {
  padding: 0 20px;

  .title {
    font-size: 18px;
    font-weight: 500;
    color: #333333;

    i {
      font-size: 16px;
      margin-right: 5px;
      vertical-align: middle;
    }
    span {
      vertical-align: middle;
    }
  }

  .childrenT {
    display: flex;
    margin-left: 25px;
    padding-top: 15px;
    flex-wrap: wrap;
    font-size: 16px;
    font-weight: 400;
    color: #333;

    p {
      width: 50%;
      cursor: pointer;
      margin-bottom: 20px;

      &:nth-child(2n) {
        margin: 0;
        text-align: center;
      }
    }

    .active {
      color: #3373fe;
      font-weight: 500;
      cursor: pointer;
    }
  }
}
</style>
