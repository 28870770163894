import store from "@/store";

export default function (Vue) {
  Vue.directive("auth", function (el, binding, vnode) {
    const auth = store.getters.isAuth(binding.value);
    if (auth) return;
    // 没有权限则用注释节点模拟v-if的效果
    const comment = document.createComment(" ");
    Object.defineProperty(comment, "setAttribute", { value: () => undefined });
    vnode.elm = comment;
    vnode.text = " ";
    vnode.isComment = true;
    vnode.context = undefined;
    vnode.tag = undefined;
    vnode.data.directives = undefined;
    if (vnode.componentInstance) {
      vnode.componentInstance.$el = comment;
    }
    if (el.parentNode) {
      el.parentNode.replaceChild(comment, el);
    }
  });
}
