<template>
  <div class="top-menu">
    <div class="top clearfix">
      <!-- logo部分 -->
      <div class="l" :class="{ min: isCollapse }">
        <div class="login-box">
          <div class="logobox">
            <img :src="$baseImgUrl + $store.state.baseBrandConf.brand_icon" alt class="logo" />
            <span class="logotext">{{ $store.state.baseBrandConf.brand_name }}</span>
          </div>
        </div>
      </div>
      <!-- 菜单部分 -->
      <div class="r">
        <div class="tabs">
          <div class="_f">
            <div
              v-for="(item, idx) in firstLevelRoute"
              :key="idx"
              class="tabs-li"
              :class="{
                active: item.path.indexOf(currFirstLevelPath) === 0,
              }"
              @click="goRouter(item)"
            >
              <p>
                <i v-if="item.meta && item.meta.menuIcon" :class="item.meta.menuIcon"></i>
                {{ item.meta.menuTitle }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- 操作部分 -->
      <div class="menu-r">
        <el-popover placement="bottom" popper-class="setup_pop" width="50" trigger="click">
          <div class="setup_box">
            <div v-auth="`Setting`" @click="$router.push('/setting')" class="setup_box_two">
              <img src="@/assets/img/common/xitong_icon.png" alt="" />
              <p class="setup_box_two_txt">系统管理</p>
            </div>
          </div>
          <el-button size="small" icon="el-icon-setting" class="setupBtn" round slot="reference">设置</el-button>
        </el-popover>
        <el-dropdown size="medium">
          <span class="el-dropdown-link">
            <p class="inline">
              <el-button style="height: 24px" class="inline" type="primary" icon="el-icon-user" circle></el-button>
            </p>
            <span>{{ loginData ? loginData.name : "" }}</span>
            <i class="inline el-icon-caret-bottom el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item icon="el-icon-back" @click.native="signOut">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div style="height: 56px"></div>
  </div>
</template>

<script>
export default {
  name: "topMenu",
  props: ["isCollapse"],
  data() {
    return {};
  },
  computed: {
    // 登录数据
    loginData() {
      return this.$store.state.loginData;
    },
    // 一级路由
    firstLevelRoute() {
      return this.$router.options.routes.filter((item) => {
        if (!item.meta || !item.meta.menuTitle) return false;
        // 校验路由的情况
        if (item.meta.isAuth === true) return this.$store.getters.isAuth(item.name);
        return true;
      });
    },
    // 当前一级路由路径
    currFirstLevelPath() {
      return `/${this.$route.path.split("/")[1]}`;
    },
  },
  methods: {
    // 退出登录
    signOut() {
      this.$confirm("是否继续退出登录?", "提示")
        .then(() => {
          this.$store.commit("updateLoginData", "");
          this.$router.push("/login");
        })
        .catch(() => {});
    },
    // 路由跳转
    goRouter(item) {
      this.$router.push(item.path);
    },
  },
};
</script>

<style>
@media screen and (min-width: 1200px) and (max-width: 1366px) {
  .top-menu .top .r .tabs .tabs-li {
    margin-right: 10px !important;
  }
}
</style>
<style lang="less" scoped>
.down-wj {
  li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    img {
      width: 32px;
      height: 32px;
    }
    .d {
      width: 150px;
      // display: inline-block;
    }
  }
}
.down-d {
  /deep/ .el-badge__content {
    right: 36px;
    top: 22px;
  }
}
.top-menu {
  position: relative;
  /deep/ .el-dropdown {
    color: #fff;
  }

  .top {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    box-sizing: border-box;
    background: linear-gradient(146deg, #327eff 0%, #327eff 100%);
    box-shadow: 4px 0px 6px 0px rgba(0, 19, 38, 0.06);
    height: 58px;
    display: flex;
    .l {
      width: 262px;
      transition: 0.5s all;
      overflow: hidden;
      &.min {
        width: 63px;
        .login-box {
          margin-left: 5px;
        }
      }
    }
    .r {
      flex: 1;
      line-height: 56px;
      font-size: 16px;
      color: #fff;
      .icon-box {
        display: inline-block;
        padding: 0 30px 0 26px;
      }
      i {
        cursor: pointer;
      }
      .tabs {
        display: inline-block;
        font-size: 14px;
        text-align: center;
        width: calc(100% - 30px);
        ._f {
          display: flex;
        }
        .tabs-li {
          padding: 0 10px;
          min-width: 110px;
          margin-right: 30px;
          box-sizing: border-box;
          cursor: pointer;
          display: inline-block;
          // flex: 1;
          i {
            margin-right: 10px;
          }
          &.active {
            // padding: 0 10px;
            color: #fff;
            height: 53px;
            border-bottom: 3px solid #fff;
          }
        }
      }
    }
    .login-box {
      // transition: 0.5s all;
      // line-height: 22px;
      // height: 22px;
      // display: inline-block;
      // padding-left: 60px;
      margin-top: 10px;
      margin-left: 60px;
      // position: relative;
      // > div {
      //   width: 48px;
      //   height: 48px;
      //   border-radius: 2px;
      //   overflow: hidden;
      //   position: absolute;
      //   margin-top: -13px;
      //   left: 0;
      //   > img {
      //     width: 100%;
      //     height: 100%;
      //   }
      // }
      // img {
      //   height: 22px;
      // }
    }
    > .menu-r {
      width: 240px;
      line-height: 56px;

      .setupBtn {
        background-color: #327eff;
        color: #fff;
        margin-right: 20px;
      }
      > i {
        font-size: 16px;
        color: #ffffff;
        cursor: pointer;
        margin-right: 19px;
        position: relative;
        &.hasnews {
          &:after {
            content: "";
            display: block;
            width: 6px;
            height: 6px;
            background: #fa5271;
            border-radius: 3px;
            position: absolute;
            top: -2px;
            right: 0px;
          }
        }
      }
      .inline {
        display: inline-block;
        font-size: 12px;
        color: #409eff;
        cursor: pointer;
        .el-button {
          padding: 5px;
          margin-top: -4px;
          background: #fff;
        }
        &:hover {
          color: #0099ff;
        }
        i {
          font-size: 19px;
          margin-top: -3px;
        }
      }
    }
  }
}
.logobox {
  margin-top: 15px;
  display: flex;
  align-items: center;
  .logo {
    width: 27px;
    height: 27px;
    // height: 28px;
    margin-right: 10px;
  }
  .logotext {
    font-size: 22px;
    color: #fff;
  }
}
</style>
