import Vue from "vue";
import VueRouter from "vue-router";
import workbench from "./workbench";
import marketing from "./marketing";
import setting from "./setting";

Vue.use(VueRouter);

/**
 * isAuth 是否需要校验路由
 * menuTitle 存在与否表示是否是渲染成菜单
 * children的第一层是菜单的分组 渲染时候需要判断子路由权限至少有一个存在才显示
 */
const routes = [
  {
    path: "/",
    component: () => import("../views/WorkBench/Index.vue"),
    redirect: "/workbench",
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login/Index.vue"),
  },
  workbench,
  marketing,
  setting,
  {
    path: "*",
    component: () => import("@/views/NoFound.vue"),
  },
];

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};

const router = new VueRouter({
  routes,
});

export default router;
