import { Message } from "element-ui";
import Vue from "vue";
import App from "./App.vue";
import { BaseError } from "./libs/error";
import logger from "./libs/logger";
import router from "./router";
import store from "./store";
import "@antv/x6-vue-shape";
import elementUI from "@/libs/elmentUi";
import config from "./config";
import direvtive from "@/libs/direvtive";

Vue.config.productionTip = false;

/** 错误处理 */
function errorHandle(errType, err, info = "", isToast = true) {
  // 自定义错误特殊处理
  if (err instanceof BaseError) {
    errType = err.name;
    if (err.globalShow === false) return;
  }
  // 错误提示
  logger.error(errType, err, info);
  if (isToast) Message.error(`${errType}: ${err.message || JSON.stringify(err)}`);
}
Vue.config.errorHandler = function (err, vm, info) {
  errorHandle("应用错误", err, info, false);
};
window.addEventListener("unhandledrejection", (event) => {
  event.preventDefault();
  event.promise.catch((err) => {
    errorHandle("unhandledrejection", err, "", false);
  });
});

// 挂载elementUi
elementUI(Vue);
// 挂载指令
direvtive(Vue);

// 挂载属性
Vue.prototype.$logger = logger;
Vue.prototype.$baseImgUrl = config.baseCdnUrl;

// 全局路由钩子
router.beforeEach((to, from, next) => {
  logger.debug("路由跳转", { from, to });

  //每次跳转将滚动条滚动到最上面
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;

  // 登录界面直接进入
  if (to.path === "/login") return next();
  // 用户未登录则跳转到登录页面
  if (!store.state.loginData || !store.state.loginData.uuid) return next("/login");
  // 校验路由权限
  if (to.meta && to.meta.isAuth === true) {
    const verify = store.getters.isAuth(to.name);
    if (verify === true) return next();
    Message.error("没有权限");
    return next(false);
  }
  next();
});

async function init() {
  // 获取基本品牌配置
  store.dispatch("getBaseConfigBrand");

  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
}

init().catch((err) => errorHandle("初始化错误", err));
