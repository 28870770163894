export default {
  path: "/workbench",
  name: "WorkBench",
  component: () => import("../views/WorkBench/Index.vue"),
  redirect: "/workbench/marketingData",
  meta: {
    menuTitle: "工作台",
    menuIcon: "icon-workbench",
  },
  children: [
    {
      path: "",
      component: () => import("../views/WorkBench/Index.vue"),
      redirect: "/workbench/marketingData",
      meta: {
        menuTitle: "数据概览",
        menuIcon: "icon-workbench-data",
      },
      children: [
        // {
        //   path: "/workbench/guide",
        //   name: "WorkBenchGuide",
        //   component: () => import("../views/WorkBench/Guide.vue"),
        //   meta: {
        //     menuTitle: "使用指引",
        //   },
        // },
        {
          path: "/workbench/marketingData",
          name: "WorkBenchMarketingData",
          component: () => import("../views/WorkBench/MarketingData.vue"),
          meta: {
            menuTitle: "营销数据",
            isAuth: true,
          },
        },
        {
          path: "/workbench/channelData",
          name: "WorkBenchChannelData",
          component: () => import("../views/WorkBench/ChannelData.vue"),
          meta: {
            menuTitle: "触达数据",
            isAuth: true,
          },
        },
      ],
    },
  ],
};
