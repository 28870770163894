import { getBaseConfigBrand } from "@/api/common";
import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loginData: {
      group: {
        rules_url: [],
      },
    }, // 登录信息
    baseBrandConf: {
      brand_icon: "",
      brand_name: "",
    }, // 基本品牌配置
  },
  getters: {
    // 判断是否有权限
    isAuth(state) {
      return (name) => {
        return state.loginData && !!state.loginData.group.rules_url.find((item) => item === name);
      };
    },
  },
  mutations: {
    // 更新登录信息
    updateLoginData(state, payload) {
      state.loginData = payload;
    },
    // 更新基本品牌配置
    updateBaseBrandConf(state, payload) {
      state.baseBrandConf = payload;
    },
  },
  actions: {
    // 获取基本品牌配置
    async getBaseConfigBrand(context) {
      const { result } = await getBaseConfigBrand();
      if (result) {
        context.commit("updateBaseBrandConf", result);
      }
    },
  },
  modules: {},
  plugins: [createPersistedState({ storage: window.localStorage })],
});
