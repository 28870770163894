export default {
  /** 项目名字 */
  name: "base-admin",
  /** 是否mock */
  isMock: false,
  /** 日志等级 trace,debug,info,warn,error */
  logLevel: "trace",
  /** 接口前缀  */
  apiPrefix: "https://gymoo-ma-api.gymooit.cn/v1",
  /** cdn前缀 */
  baseCdnUrl: "https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/",
  /** 上传地址 */
  uploadUrl: "https://gymoo-yunce-api.gymooit.cn/v1/common/UploadFile",

  /** 节点 */
  node: {
    emit: {
      EmitAddTagGroup: {
        icon: require("@/assets/img/marketing/emit/icon-addTagGroup.png"),
        title: "选择标签组",
        tooltip: "当用户加入指定标签组时触发",
        type: 2,
      },
      EmitAddMemberGroup: {
        icon: require("@/assets/img/marketing/emit/icon-addTagGroup.png"),
        title: "选择会员组",
        tooltip: "当用户加入指定会员组时触发",
        type: 3,
      },
      EmitChangeAttr: {
        icon: require("@/assets/img/marketing/emit/icon-changeAttr.png"),
        title: "属性变更",
        tooltip: "系统已存在的【联系人属性】发生变更且满足条件才会触发客户旅程，新建联系人时预设属性满足条件不会触发",
        type: 4,
      },
      EmitEvent: {
        icon: require("@/assets/img/marketing/emit/icon-eventBranch.png"),
        title: "事件分支",
        tooltip: "当用户完成某个用户事件时触发，用户事件同步云策业务系统",
        type: 5,
      },
      EmitXcx: {
        icon: require("@/assets/img/marketing/emit/icon-xcx.png"),
        title: "微信小程序",
        tooltip: "当用户启动、展示、隐藏、打开、分享小程序时触发",
        type: 6,
      },
      EmitGzh: {
        icon: require("@/assets/img/marketing/emit/icon-gzh.png"),
        title: "微信公众号",
        tooltip: "当用户关注、留言、点击菜单、取消关注公众号时触发",
        type: 7,
      },
      EmitQywx: {
        icon: require("@/assets/img/marketing/emit/icon-qywx.png"),
        title: "企业微信",
        tooltip: "当员工添加用户、用户删除员工、用户加群时触发",
        type: 8,
      },
    },
    action: {
      ActionStart: {
        icon: require("@/assets/img/marketing/emit/icon-crontab.png"),
        title: "开始",
        tooltip:
          "通过【开始触发条件】您可以在指定时间点加入客户旅程； 场景： 【单次触发】定时明天x点，执行自动化流程； 【重复触发】重复定时每天/周/月的x点，执行自动化流程； 【立即触发】不用设定时间，立即执行自动化流程。",
        type: 1,
      },
      ActionAddTagGroup: {
        icon: require("@/assets/img/marketing/action/icon-addTagGroup.png"),
        title: "加入标签组",
        tooltip: "选择您想要将满足条件的用户加入的标签组，可选择一个或多个签组组",
        type: 9,
      },
      ActionRemoveTagGroup: {
        icon: require("@/assets/img/marketing/action/icon-removeTagGroup.png"),
        title: "移出标签组",
        tooltip: "选择您想要将满足条件的用户移出的标签组，可选择一个或多个签组组",
        type: 10,
      },
      ActionAddMemberGroup: {
        icon: require("@/assets/img/marketing/action/icon-removeTagGroup.png"),
        title: "加入会员组",
        tooltip: "选择您想要将满足条件的用户加入的会员组，可选择一个或多个会员组",
        type: 11,
      },
      ActionRemoveMemberGroup: {
        icon: require("@/assets/img/marketing/action/icon-removeTagGroup.png"),
        title: "移出会员组",
        tooltip: "选择您想要将满足条件的用户移出的会员组，可选择一个或多个会员组提示",
        type: 12,
      },
      ActionDelay: {
        icon: require("@/assets/img/marketing/action/icon-delay.png"),
        title: "延时器",
        tooltip: "根据业务需要，设置好指定等待时长,到达指定时间执行下一步动作",
        type: 13,
      },
      ActionChangeAttr: {
        icon: require("@/assets/img/marketing/action/icon-changeAttr.png"),
        title: "变更属性",
        tooltip:
          "属性值输入的内容/设置的动态参数需满足更改属性的属性类型. 属性值输入的内容/设置的动态参数需满足更改属性的属性类型。例如选择更改的属性类型为数字型，若输入的内容为文字，两者类型不匹配，则进入旅程的联系人该属性值不会进行变更。 所选择的属性与属性值必须全部匹配，若有其一属性与属性值类型不匹配的情况，则所有属性均不会进行变更",
        type: 14,
      },
      ActionAb: {
        icon: require("@/assets/img/marketing/action/icon-ab.png"),
        title: "A/B分配",
        tooltip: "将进入客户旅程的用户随机分成两个指定比例（百分比）分别进入两个不同的路径",
        type: 15,
      },
      ActionGzh: {
        icon: require("@/assets/img/marketing/action/icon-notify.png"),
        title: "公众号通知",
        tooltip: "通过微信公众号消息触达用户",
        type: 16,
      },
      ActionQywx: {
        icon: require("@/assets/img/marketing/action/icon-notify.png"),
        title: "企业微信通知",
        tooltip: "通过企业微信消息触达用户",
        type: 17,
      },
      ActionMsg: {
        icon: require("@/assets/img/marketing/action/icon-notify.png"),
        title: "短信通知",
        tooltip: "通过短信触达用户",
        type: 18,
      },
      ActionXcx: {
        icon: require("@/assets/img/marketing/action/icon-notify.png"),
        title: "小程序通知",
        tooltip: "通过微信小程序消息触达用户",
        type: 19,
      },
      ActionStop: {
        icon: require("@/assets/img/marketing/action/icon-stop.png"),
        title: "终止旅程",
        tooltip:
          "客户旅程以最后一个动作为结束点，自行终止旅程。也可以使用【终止旅程】动作组件，让进入客户旅程的联系人立即终止该旅程",
        type: 20,
      },
    },
  },
};
